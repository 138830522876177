'use client'

import { Cell, Image, Text } from '@vinted/web-ui'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { useFetch } from '@marketplace-web/shared/api-client'
import { navigateToPage } from '@marketplace-web/shared/browser'
import { FormattedRelativeTime } from '@marketplace-web/shared/i18n'
import { useAsset } from '@marketplace-web/shared/ui-helpers'
import { BRAZE_VINTED_LOGO_IMAGE_PATH } from '_libs/common/braze/constants'
import { ConversationInitiatorType } from 'constants/conversation'
import { InboxNotificationType } from 'constants/inbox-notification'
import { CONVERSATION_URL } from 'constants/routes/inbox'
import { NotificationEntryType } from 'constants/user-notifications'
import { createItemConversationThread } from 'data/api'
import { GenericInboxNotificationModel } from 'types/models/generic-inbox-notification'
import { transformConversationResponse } from 'data/transformers/conversation'

type Props = {
  notification: GenericInboxNotificationModel
  onClick: (clickedElementData: Record<string, string | undefined>) => void
  onEnter: () => void
  defaultHighlighted?: boolean
}

const InboxNotificationItem = ({ notification, onClick, onEnter, defaultHighlighted }: Props) => {
  // we are using `useState` to protect `isHighlighted` from unwanted `defaultHighlighted` changes
  // so the setter is unused
  // eslint-disable-next-line react/hook-use-state
  const [isHighlighted] = useState(defaultHighlighted)
  const asset = useAsset()
  const { ref } = useInView({
    onChange: inView => inView && onEnter(),
  })

  const { type, entryType, body, note, link, subjectId, time, photoUrl, photoStyle } = notification

  const { fetch: createConversation, transformedData: conversation } = useFetch(
    createItemConversationThread,
    response => transformConversationResponse(response.conversation),
  )

  useEffect(() => {
    if (!conversation) return

    navigateToPage(CONVERSATION_URL(conversation.id))
  }, [conversation])

  const renderImage = () => {
    const fallbackImage =
      type === InboxNotificationType.Braze ? asset(BRAZE_VINTED_LOGO_IMAGE_PATH) : undefined

    return (
      <Image
        role="img"
        src={photoUrl || fallbackImage}
        fallbackSrc={fallbackImage}
        size={Image.Size.Large}
        styling={photoStyle} // TODO: check if we can use single style, rather than dynamic
      />
    )
  }

  const renderBody = () => (
    <>
      <Text as="span" text={body} html width={Text.Width.Parent} />
      {!!note && (
        <Text as="h3" type={Text.Type.Subtitle} width={Text.Width.Parent} text={note} html /> // TODO: check if this is needed
      )}
      <Text
        as="h3"
        text={<FormattedRelativeTime value={time} />}
        type={Text.Type.Subtitle}
        width={Text.Width.Parent}
      />
    </>
  )

  const createConversationFromNotification = () => {
    if (!link) return

    const receiverId = link.substring(link.lastIndexOf('=') + 1)

    if (subjectId) {
      createConversation({
        itemId: subjectId.toString(),
        receiverId,
        initiator: ConversationInitiatorType.SellerEntersNotification,
      })
    }
  }

  // Temporary click interceptor to collect more information for tracking purposes
  // To be removed after AB test is scaled
  const interceptClick = (event: React.MouseEvent<Element>) => {
    const element = event.target as HTMLElement

    const getClickedElementData = () => {
      const href = element.getAttribute('href')
      if (href) return { clicked_element: 'link', href, notification_link: link }

      return { clicked_element: 'body', notification_link: link }
    }

    createConversationFromNotification()

    onClick(getClickedElementData())
  }

  const isNotificationLinkShown = entryType !== NotificationEntryType.ItemFavourite && link

  return (
    <div ref={ref} data-testid="inbox-notification-intersection-wrapper">
      <Cell
        type={link ? Cell.Type.Navigating : undefined}
        onClick={interceptClick}
        tabIndex={0}
        highlighted={isHighlighted}
        prefix={renderImage()}
        body={renderBody()}
        url={isNotificationLinkShown ? link : undefined}
        testId={`user-notification-item${isHighlighted ? '-highlighted' : ''}`}
      />
    </div>
  )
}

export default InboxNotificationItem
