import { partition } from 'lodash'
import { useContext, useMemo, useSyncExternalStore } from 'react'

import { BrazeContext } from '_libs/common/braze/containers/BrazeProvider'

function useBrazeInboxNotificationCards() {
  const { inboxNotificationCardStore } = useContext(BrazeContext)

  const allNotificationCards = useSyncExternalStore(
    inboxNotificationCardStore.subscribe,
    () => inboxNotificationCardStore.state,
    () => null,
  )

  const [brazeControlNotificationCards, brazeNotificationCards] = useMemo(
    () =>
      allNotificationCards
        ? partition(allNotificationCards, card => card.extras.variant === 'control')
        : [undefined, undefined],
    [allNotificationCards],
  )

  return {
    brazeNotificationCards,
    brazeControlNotificationCards,
  }
}

export default useBrazeInboxNotificationCards
